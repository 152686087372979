@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SF Mono';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/sf-mono/SFMonoRegular.woff') format('woff');
}

@font-face {
  font-family: 'SF Mono';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/sf-mono/SFMonoMedium.woff') format('woff');
}

@font-face {
  font-family: 'SF Mono';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/sf-mono/SFMonoBold.woff') format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500');

@layer base {
  html,
  body {
    @apply font-inter;
  }
  .outline-solid {
    outline: 1px solid;
  }
  #nprogress .bar {
    background: '#0078FF' !important;
  }
  .css-yrjgl1,
  [data-css-yrjgl1] {
    display: none !important;
  }
}

@layer components {
  .login-with-google-btn {
    transition: background-color 0.3s, box-shadow 0.3s;
    color: #757575;
  }

  .login-with-google-btn:hover {
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
    transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  }

  div div .btn-black .css-11r1ktn {
    filter: invert(205%) sepia(103%) saturate(0%) hue-rotate(330deg) brightness(500%) contrast(100%);
  }

  .nav-menu-mobile {
    transform: translate3d(100%, 0, 0);
    transition: transform 0.25s ease-in-out;
  }

  .navbar-mobile-overlay {
    content: '';
    background: rgba(75, 86, 102, 0.4);
  }

  .ql-snow .ql-tooltip {
    left: 0 !important;
    z-index: 20 !important;
  }

  .template-preview a {
    @apply text-mailstand_blue-8 underline;
  }

  .new-mailbox.ql-snow .ql-picker-options,
  .new-template.ql-snow .ql-picker-options {
    @apply !fixed !z-[9999] !min-w-[100px] !top-[70%];
  }

  .new-mailbox.ql-toolbar .ql-picker-options {
    @apply !top-[86%];
  }

  .new-template.ql-toolbar .ql-picker-options {
    @apply !top-[90%];
  }

  .ql-snow .ql-size .ql-picker-options {
    @apply !max-h-[220px] !h-auto !overflow-y-auto !min-w-[70px];
  }

  .ql-container.ql-snow {
    @apply !border !border-mailstand_gray-13;
  }

  .cell-editable:hover .draw-btn {
    display: block;
  }

  .new-template .ql-container {
    @apply px-2;
  }

  .campaign-option-checkbox div,
  .mailstand-option-checkbox.circle div,
  .plan-option-checkbox div {
    @apply !rounded-full;
  }

  .plan-option-checkbox .css-9mzsny:checked + div,
  .mailstand-option-checkbox .css-9mzsny:checked + div {
    @apply !bg-mailstand_green;
  }

  .mailstand-switch .css-1yog12j:checked + div {
    @apply !bg-mailstand_green;
  }

  .mailstand-switch .css-1yog12j[disabled] + div {
    @apply !opacity-100;
  }

  .new-template .ql-container .ql-editor {
    height: 22rem;
  }

  .new-template.ql-snow .ql-align .ql-picker-options,
  .new-mailbox.ql-snow .ql-align .ql-picker-options {
    @apply !min-w-[30px];
  }

  .new-mailbox .ql-container .ql-editor {
    height: 12rem;
  }

  .ql-container.ql-snow .ql-editor {
    @apply !overflow-y-auto !resize-y;
    max-height: 50vh;
  }

  .quill > .ql-container > .ql-editor.ql-blank::before {
    @apply !not-italic;
  }

  .contact-preview .ql-container .ql-editor {
    @apply !px-[45px] min-h-[8rem];
  }

  .message-editor .ql-container .ql-editor {
    @apply min-h-[2rem] !pb-0 !resize-none;
  }

  .compose-message-editor .ql-container .ql-editor {
    @apply min-h-[6rem] !pb-0 !border-t;
  }

  .compose-message-editor.show-placeholder .ql-container .ql-editor::before {
    color: rgba(0, 0, 0, 0.6);
    content: attr(data-placeholder);
    pointer-events: none;
    left: 15px;
    right: 15px;
    @apply absolute !block;
  }

  .compose-message-signature {
    @apply mb-4;
  }

  .message-editor .ql-container.ql-snow,
  .compose-message-editor .ql-container.ql-snow {
    @apply !border-0;
  }

  .embed-signature-content,
  .message-signature-content {
    @apply hover:border-2 hover:border-mailstand_blue-10 px-3 pb-0.5 -mx-3 hover:-mx-3.5 hover:-my-0.5 rounded relative !cursor-default;
  }

  .form-editor .embed-signature-content:hover .remove-signature,
  .form-editor .message-signature-content:hover .remove-signature {
    @apply block;
  }

  .form-editor .remove-signature {
    @apply hidden absolute right-2 top-1 text-xs w-4 h-4 rounded-full bg-mailstand_neutral-n800 hover:bg-mailstand_neutral-n900 text-white;
  }

  .form-editor .remove-signature:before {
    content: attr(data-text);
    @apply absolute right-0 mt-[18px] -mr-[5px] w-[105px] p-1 z-20 rounded-full text-[11px] bg-black text-white text-center hidden;
  }

  .form-editor .remove-signature:hover:before {
    @apply block;
  }

  .message-email-content ul {
    @apply list-disc pl-6 md:pl-10 pb-2;
  }

  .message-email-content ol {
    @apply pl-6 md:pl-10 pb-2;
    list-style-type: auto;
  }

  .message-email-content a {
    @apply text-mailstand_blue underline;
  }

  .form-editor .image-blot {
    @apply w-full relative mb-2;
  }

  .form-editor .image-blot:before {
    content: attr(data-text);
    @apply hidden absolute -bottom-[25px] left-0 mt-[18px] py-1 px-3 z-20 rounded-full text-[11px] bg-black text-white text-center;
    white-space: nowrap;
  }

  .form-editor .image-blot:hover:before {
    @apply block;
  }

  .rdg-border {
    border: 1px solid #e4e7eb !important;
  }

  .custom-cell-header {
    @apply !outline-none hover:!bg-mailstand_gray-11 uppercase !border-b font-semibold text-mailstand_neutral text-[10px] !bg-white;
    border-inline-end: 1px solid #e4e7eb !important;
    border-block-end: 1px solid #e4e7eb !important;
  }

  .custom-cell {
    @apply !outline-none !px-0 text-xs !border-b;
    border-inline-end: 1px solid #e4e7eb !important;
    border-block-end: 1px solid #e4e7eb !important;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text !important;
  }

  .file-uploader .css-1pr25ar div {
    width: auto !important;
    margin-bottom: -15px;
  }

  .table.sticky [data-sticky-last-left-td] {
    box-shadow: 0.5px 0px 0.5px #ccc !important;
  }

  .line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  /* custom scroll */
  #board::-webkit-scrollbar,
  #popup-scroll::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  #board::-webkit-scrollbar-thumb,
  #popup-scroll::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  .calendar-container {
    @apply flex w-fit items-start justify-center border border-black/20 bg-mailstand_gray relative z-50 rounded-[0.3rem];
  }

  .calendar-container .react-datepicker__month-container {
    @apply !w-full;
  }

  .calendar-container .react-datepicker__day-names {
    @apply flex items-center justify-center mt-2;
  }

  .calendar-container .react-datepicker__day-name {
    @apply w-6 m-0 mr-3 text-xs;
  }
  .calendar-container .react-datepicker__day-name:last-child {
    @apply mr-0;
  }

  .calendar-container .react-datepicker__month {
    @apply my-3 mx-0;
  }

  .calendar-container .react-datepicker__week {
    @apply flex justify-center items-center ml-0 mb-2 w-full;
  }

  .calendar-container .react-datepicker__day {
    @apply w-max flex items-center justify-center font-normal text-xs select-none m-0 !border-none !outline-none !rounded-none;
  }

  .calendar-container .react-datepicker__day:hover .day-customize {
    @apply bg-mailstand_neutral/70 text-white font-semibold rounded-[6px];
  }


  .calendar-container .react-datepicker__day--in-range {
    @apply bg-transparent;
  }

  .calendar-container .react-datepicker__day--selected:hover {
    @apply bg-mailstand_neutral rounded-full font-semibold;
  }

  .calendar-container .react-datepicker__day--range-end {
    @apply !bg-mailstand_neutral text-white rounded-full font-semibold;
  }

  .calendar-container .react-datepicker__day--selecting-range-end.react-datepicker__day--keyboard-selected:not(.react-datepicker__day--today) .day-customize {
    @apply rounded bg-black text-white;
  }

  .calendar-container .react-datepicker__day--keyboard-selected {
    @apply bg-transparent;
  }

  .calendar-container
    .react-datepicker__day--keyboard-selected.react-datepicker__day--selecting-range-end {
    @apply top-[-1px] right-[-1px] bottom-[-1px] w-4 bg-mailstand_gray;
  }

  .calendar-container .react-datepicker__day .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range {
    @apply !bg-mailstand_gray-26;
  }

  .calendar-container
    .react-datepicker__day.react-datepicker__day--selected:not(
      .react-datepicker__day--selecting-range-start
    ) {
    @apply !bg-mailstand_gray;
  }

  .calendar-container .react-datepicker__day--disabled {
    @apply pointer-events-none;
  }

  .day-wrapper {
    @apply flex items-center m-0 w-[36px] pl-[8px] relative;
  }

  .day-customize {
    @apply flex items-center justify-center w-6 h-6 relative z-50;
  }

  .calendar-container .react-datepicker__day:hover {
    @apply !bg-transparent;
  }

  .calendar-container .react-datepicker__day--in-range .day-wrapper {
    @apply bg-mailstand_gray-26;
  }

  .calendar-container .react-datepicker__day--in-range .day-customize {
    @apply text-black;
  }

  .calendar-container .react-datepicker__day--selected .day-wrapper {
    @apply rounded-l-full;
  }

  .calendar-container
    .react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end
    .day-wrapper:after {
    content: '';
    @apply block absolute bg-mailstand_gray top-[-1px] bottom-[-1px] left-0 right-0;
  }

  .calendar-container .react-datepicker__day--selected .day-wrapper:before {
    content: '';
    @apply block absolute bg-mailstand_gray top-[-1px] bottom-[-1px] left-[0px] right-[4px] z-0 rounded-r-full;
  }

  .calendar-container .react-datepicker__day--selected .day-customize,
  .calendar-container .react-datepicker__day--range-end .day-customize {
    @apply bg-mailstand_neutral rounded-full font-semibold text-white;
  }

  .calendar-container .react-datepicker__day--range-end .day-wrapper:before {
    content: '';
    @apply block absolute bg-mailstand_gray top-[-1px] bottom-[-1px] right-[-1px] left-2;
  }

  .calendar-container
    .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end:not(
      .react-datepicker__day--selecting-range-start
    )
    .day-wrapper:before {
    content: '';
    @apply block absolute bg-mailstand_gray-26 top-0 bottom-0 left-0 w-5;
  }

  .calendar-container
    .react-datepicker__day--range-end:not(.react-datepicker__day--range-start)
    .day-wrapper:after {
    content: '';
    @apply block absolute bg-mailstand_gray-26 top-0 bottom-0 left-0 w-5;
  }

  .calendar-container .react-datepicker__day--today {
    @apply text-[green];
  }

  .calendar-container
    .react-datepicker__day--today:not(.react-datepicker__day--selected)
    .day-customize {
    @apply text-[green] font-bold;
  }

  .calendar-container .react-datepicker__day--today:hover .day-customize,
  .calendar-container .react-datepicker__day--selected.react-datepicker__day--today .day-customize,
  .calendar-container
    .react-datepicker__day--range-end.react-datepicker__day--today
    .day-customize {
    @apply !bg-[green] !text-white !rounded-full;
  }

  .formatEditor {
    margin: 0 !important;
  }

  .tox .tox-editor-header {
    background-color: #f5f7f9 !important;
  }

  .tox .tox-editor-header .tox-toolbar-overlord .tox-toolbar__primary {
    display: flex;
    justify-content: center;
    background-color: #f5f7f9 !important;
  }

  .tox .tox-menu {
    max-height: 200px !important;
  }

  .tox .tox-collection__item-label {
    font-size: 12px !important;
  }

  .tox .tox-menu__searcher {
    font-size: 12px !important;
    line-height: 20px !important;
    padding: 4px 5px !important;
  }

  .tox .tox-tbtn.tox-tbtn--select {
    display: flex !important;
    align-items: center !important;
    border: solid 1px #ccc;
    margin: 0;
  }

  .tox .tox-tbtn__select-label {
    display: flex;
    align-items: center;
    line-height: 20px;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #474d66;
  }

  .tox .tox-tbtn.tox-tbtn--select.tox-tbtn--bespoke {
    width: 70px !important;
  }

  .tox .tox-tbtn.tox-tbtn--select.tox-tbtn--bespoke:hover {
    border: solid 1px #ccc;
  }

  .tox .tox-statusbar .tox-statusbar__text-container {
    display: none;
  }

  .tox .tox-toolbar__group {
    padding: 0 4px !important;
  }

  .tox .tox-statusbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: -25px !important;
    background: transparent !important;
    border: none !important;
  }

  .tox .tox-statusbar .tox-statusbar__resize-handle {
    position: relative !important;
    z-index: 999 !important;
  }

  #btn-upload-img {
    display: none;
  }
}
